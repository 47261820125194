/* MacroService.module.css */
.container {
  margin: 5rem 5vw;
  box-sizing: border-box;
}

.badgeContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.badge {
  padding: 0.625rem 1.25rem;
  border-radius: 50px;
  text-align: center;
  text-transform: uppercase;
  border: 1px solid #0a47c9;
  color: #0a47c9;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.2;
}

.descDiv {
  display: flex;
  justify-content: center;
}

.description {
  color: #000;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 1.6875rem;
  font-weight: 400;
  line-height: 1.54;
}

.content {
  display: flex;
  flex-direction: column;
  margin-top: 4.5rem;
}

.item {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ddd;
  padding: 2rem 0px;
  transition: all 0.3s ease;
}

.itemText {
  color: #0a47c9;
  font-family: Poppins, sans-serif;
  font-size: 30px;
  font-weight: 400;
  line-height: 1.2;
  text-transform: uppercase;
}

.itemDescription {
  color: #2d2d2d;
  font-family: Poppins, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  /* line-height: 1.54; */
  text-align: left;
  color: #2d2d2d;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 154%; /* 24.64px */
}

.separator {
  border: 0;
  border-top: 1px solid #ddd;
  margin: 0.625rem 0;
}

.descriptionContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.itemDescriptionDiv {
  width: 48vw;
}

.subDesc {
  color: #2d2d2d;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.expandedContent {
  display: flex;
  margin-top: 48px;
}

.leftColumn {
  flex: 1;
}

.rightColumn {
  flex: 1;
  display: flex;
  justify-content: end;
  align-items: start;
}

.image {
  /* max-width: 100%; */
  width: 40vw;
  height: auto;
}

.keyFeaturesButton {
  display: flex;
  width: 135px;
  padding: 13.04px 12.273px 13.04px 0px;
  justify-content: center;
  align-items: flex-end;
  gap: 16px;
  border-radius: 38.352px;
  border: 0.767px solid #0a47c9;
  background-color: #fff;
  color: #0a47c9;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 16.8px */
  letter-spacing: -0.24px;
  margin-top: 34px;
}

.keyFeatueDiv {
  margin-top: 24px;
}

.title {
  color: #2d2d2d;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-right: 10px;
}

.keyDescription {
  color: #2d2d2d;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.listsDiv {
  margin-bottom: 12px;
}

@media (max-width: 768px) {
  .container {
    margin: 4rem 5vw 0px 5vw;
    box-sizing: border-box;
  }
  .badgeContainer {
    margin-bottom: 12px;
  }

  .badge {
    font-size: 8px;
    padding: 0.5rem 1rem;
  }

  .description {
    font-size: 8px;
    padding: 0 0px;
  }

  .content {
    margin-top: 1rem;
  }

  .item {
    padding: 0.5rem 0;
  }

  .itemText {
    font-size: 10px;
  }

  .itemDescription {
    color: #2d2d2d;
    font-family: Poppins;
    font-size: 6px;
    font-style: normal;
    font-weight: 400;
    line-height: 154%;
  }

  .descriptionContainer {
    flex-direction: row;
    align-items: center;
  }

  .itemDescriptionDiv {
    width: 220px;
  }

  .expandedContent {
    flex-direction: column-reverse;
    margin-top: 16px;
  }

  .leftColumn {
    margin-bottom: 1rem;
  }

  .rightColumn {
    width: 100%;
    justify-content: center;
  }

  .image {
    width: 100%;
    height: 174px;
    flex-shrink: 0;
  }
  .subDesc {
    margin-top: 12px;
    color: #2d2d2d;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
  }

  .keyFeaturesButton {
    display: flex;
    width: 54px;
    height: 17px;
    padding: 5.216px 0px;
    justify-content: center;
    align-items: center;
    gap: 6.4px;
    border-radius: 15.341px;
    border: 0.307px solid #0a47c9;
    color: #0a47c9;
    font-family: Poppins;
    font-size: 6px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 8.4px */
    letter-spacing: -0.12px;
    margin-top: 16px;
  }
  .title {
    color: #2d2d2d;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .keyDescription {
    color: #2d2d2d;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .keyFeatueDiv {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 12px 20px;
  }
}
