* {
  box-sizing: border-box;
}

.aboutUs {
  display: flex;
  flex-direction: column; /* Default to column for smaller devices */
  margin: 0rem 5rem;
  gap: 2vh;
  margin-top: 80px;
  margin-bottom: 74px;
  max-width: 1914px; /* Combined max width of both columns */
}

.leftColumn {
  flex: 1;
  max-width: 41%; /* Adjust for desktop */
  display: flex;
  flex-direction: column;
  align-items: start;
}

.badge {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 1vh;
  border-radius: 100px;
  border: 1px solid #0a47c9;
  display: inline-flex;
  padding: 12px 23px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #0a47c9;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  line-height: 120%; /* 28.8px */
}

.image {
  width: 31.4vw; /* Adjust based on your viewport */
  height: auto; /* Maintains aspect ratio */
  max-height: 25.1vw; /* Optional: use max-height to control the height */
  flex-shrink: 0;
}

.rightColumn {
  flex: 1;
  max-width: 59%; /* Adjust for desktop */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.header {
  margin-top: 0;
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 2.6rem; /* Converted from 48px */
  font-weight: 600;
  line-height: 1.34; /* Adjust line height proportionally */
}

.subHeader {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 1.1rem; /* Converted from 24px */
  font-style: normal;
  font-weight: 400;
  line-height: 1.4; /* Adjust line height proportionally */
  text-align: justify;
}

.tabs {
  display: flex;
  gap: 1vw;
  margin-bottom: 2vh;
  margin-top: 64px;
}

.tabButton {
  display: flex;
  padding: 15px 39px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #f4f4f4;
  border: none;
  width: auto; /* Default width for desktop */
}

.tabButton.active {
  background: #0a47c9;
  color: #fff;
  font-family: Poppins;
  font-size: 19px;
  font-weight: 600;
}

.description {
  margin-top: 27px;
  color: #696e73;
  font-family: Poppins;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 154%;
  text-align: justify;
}

/* Media Queries */
@media (max-width: 767px) {
  .aboutUs {
    flex-direction: column; /* Stack columns on smaller devices */
    margin: 60px 24px;
  }

  .leftColumn {
    align-items: center;
    max-width: 100%;
  }

  .badge {
    display: inline-flex;
    padding: 5px 9px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 1rem; /* Smaller font size */

    color: #0a47c9;
    text-align: center;
    font-family: Poppins;
    /* font-size: px; */
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 9.6px */
  }

  .image {
    width: 100%; /* Full width for responsive image */
    max-width: 100%;
    margin-top: 16px; /* Add margin for spacing */
  }

  .rightColumn {
    max-width: 100%;
    align-items: center;
    text-align: center; /* Center-align text */
  }

  .tabs {
    /* flex-direction: column; */
    gap: 1rem;

    /* display: flex; */
    width: 112px;
    height: 42px;
    padding: 10.616px 27.602px 11.324px 26.895px;
    justify-content: center;
    align-items: center;
    gap: 7.078px;
    flex-shrink: 0;
    margin-top: 20px;
  }

  .tabButton {
    /* width: 100%; */
    text-wrap: nowrap;
    font-size: 12px;
    width: 112px;
  }

  .description {
    text-align: center; /* Center-align description text */
    color: #696e73;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 154%; /* 18.48px */
  }

  .header {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: 134%; /* 26.8px */
  }
  .subHeader {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 134%; /* 10.72px */
  }

  .tabButton.active {
    color: #fff;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 151.523%; /* 18.183px */
    width: 112px;
    text-wrap: nowrap;
  }
}

@media (min-width: 768px) {
  .aboutUs {
    flex-direction: row; /* Maintain row layout for larger devices */
    margin-top: 160px;
  }

  .leftColumn,
  .rightColumn {
    flex: 1;
  }

  .image {
    margin-top: 218px; /* Adjust for larger screens */
    width: 31.41vw;
    height: 44.71vh;
    /* max-height: 44.72vh; */
  }
}
