.privacyPolicy {
  text-align: center;
  width: 100%;
  max-width: 1319px; /* Keep the maximum width as it was */
  margin-top: 100px;
  /* padding: 2rem; */
  /* max-width: 800px; */
  /* margin: 0 auto; */
}

.topText {
  color: #6d7a65;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 112.5% */
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

.heading {
  color: #0a47c9;
  font-family: Poppins;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 96px */
  text-transform: capitalize;
}

.text {
  color: #4e4e4e;
  text-align: center;
  font-family: Poppins;
  /* font-size: 24px; */
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 36px */
  text-transform: capitalize;
  margin: 0 177px;
}

.effectiveDate,
.subheading {
  font-size: 1rem;
  margin-bottom: 2rem;
}

.section {
  margin-top: 2rem;
}

.date {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 24px;
  color: #000;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 36px */
  text-transform: capitalize;
  gap: 64px;
}

.sectionHeading {
  color: #2b3029;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 54px */
  text-transform: capitalize;
  margin-bottom: 24px;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .privacyPolicy {
    margin: 50px auto;
    padding: 0 2rem; /* Add some padding for smaller screens */
  }

  .text {
    margin: 0 50px;
  }

  .heading {
    font-size: 48px;
  }

  .date {
    font-size: 20px;
    gap: 32px;
  }

  .sectionHeading {
    font-size: 30px;
  }
}

@media (max-width: 768px) {
  .privacyPolicy {
    margin: 20px auto;
    padding: 0 1rem; /* Less padding on very small screens */
  }

  .text {
    margin: 0 20px;
    font-size: 1rem;
  }

  .heading {
    font-size: 36px;
  }

  .date {
    font-size: 18px;
    gap: 16px;
  }

  .sectionHeading {
    font-size: 24px;
  }

  .topText {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .privacyPolicy {
    margin: 10px auto;
    padding: 0 0.5rem; /* Minimal padding on the smallest screens */
  }

  .text {
    margin: 0 10px;
    font-size: 0.9rem;
  }

  .heading {
    font-size: 28px;
  }

  .date {
    font-size: 16px;
    gap: 8px;
  }

  .sectionHeading {
    font-size: 20px;
  }

  .topText {
    font-size: 12px;
  }
}
