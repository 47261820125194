.chooseUs {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-top: 5rem; /* 80px */
  max-width: 75rem; /* 1200px */
  margin: auto;
  margin-top: 5rem; /* 80px */
}

.leftSection {
  text-align: left;
}

.badge {
  display: inline-flex;
  padding: 0.625rem 1.375rem; /* 10px 22px */
  justify-content: center;
  align-items: center;
  gap: 0.625rem; /* 10px */
  border-radius: 100px;
  border: 1px solid #0a47c9;
}

.badgeText {
  color: #0a47c9;
  text-align: center;
  font-family: Poppins;
  font-size: 1.5rem; /* 24px */
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 28.8px */
}

.description {
  margin-top: 2rem; /* 32px */
  color: #000;
  font-family: Poppins;
  font-size: 2rem; /* 24px */
  font-style: normal;
  font-weight: 400;
  line-height: 154%; /* 49.28px */
  width: 50rem; /* 800px */
}

.imageAndCardsWrapper {
  display: flex;
  gap: 52px; /* 72px gap between image and cards */
  align-items: flex-start;
  margin-bottom: 4rem;
}

.imageWrapper {
  flex: 1 1 30%;
  display: flex;
  justify-content: start; /* Align image to the start */
}

.image {
  width: 100%;
  height: auto; /* Adjust height to maintain aspect ratio */
  max-width: 670px; /* Adjust this to fit your layout */
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.rightSection {
  flex: 1 1 50%;
  display: flex;
  flex-wrap: wrap;
  gap: 32px; /* Gap between cards */
}

.card {
  background-color: #fff;
  padding: 1rem; /* Increased padding for card content */
  border: 1px solid #ddd;
  border-radius: 12px;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
  cursor: pointer;
  width: calc(50% - 1rem); /* Two cards per row, accounting for gap */
  box-sizing: border-box;
  height: 200px;
}

.cardHovered {
  background: #0a47c9;
}
.cardHovered .cardDescription {
  color: #fff;
}
.cardHovered .cardHeading {
  color: #fff;
}
.cardHeading {
  font-size: 1rem; /* Adjusted font size */
  margin-bottom: 0.5rem;
  color: #0a47c9;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: 154%; /* 36.96px */
}

.cardDescription {
  color: #000;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 144%; /* 23.04px */
}

/* Media Query for Tablets and Mobile Devices */
@media (max-width: 768px) {
  .chooseUs {
    flex-direction: column;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-top: 0rem;
    max-width: 75rem;
    margin: auto;
    margin-top: 24px;
  }

  .imageAndCardsWrapper {
    flex-direction: column;
    gap: 1rem;
    margin: 0 24px;
  }

  .imageWrapper {
    width: 100%; /* Full width for the image wrapper */
    justify-content: center; /* Center image horizontally */
  }

  .image {
    max-width: 100%; /* Ensure image is responsive */
    height: auto; /* Maintain aspect ratio */
  }

  .leftSection {
    text-align: center;
  }

  .rightSection {
    flex: 1 1 50%;
    display: flex;
    flex-wrap: wrap;
    gap: 22px; /* Gap between cards */
    width: 100%;
    justify-content: center;
  }

  .card {
    width: calc(100% - 1rem); /* Full width minus gap */
    margin-bottom: 0rem; /* Spacing between cards in single-column view */
  }

  .description {
    margin: 12px 0px 0 0px;
    color: #000;
    font-family: Poppins;
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: 154%;
    width: 100%;
  }
  .badge {
    display: inline-flex;
    padding: 5px 9px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 43.306px;
    border: 0.433px solid #0a47c9;
  }
  .badgeText {
    color: #0a47c9;
    text-align: center;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 9.6px */
  }
}
