* {
  box-sizing: border-box;
}
.footer {
  margin-top: 80px;
  width: 100%;
  height: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  background: #fafafa;
  align-items: normal;
  padding-top: 12px;
}

.heroSection {
  border-radius: 40px;
  background-color: #020609;
  justify-content: center;
  gap: 56px;
  margin: 0 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 106px;
  padding: 130px 80px;
  backdrop-filter: blur(42.66667556762695px);
}

.heroText {
  color: #fff;
  font-family: "Plus Jakarta Sans";
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: -2.16px;
}

.heroButton {
  border-radius: 93.333px;
  background: #fff;
  padding: 21.333px 42.667px;
  font-size: 18px;
  height: 56px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 56px;
  color: #010205;
  font-family: "Plus Jakarta Sans";
  font-size: 21.333px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.427px;
}

.logoImg {
  height: 35px;
  width: 185px;
}

.mainContent {
  display: flex;
  justify-content: space-between;
  max-width: 1920px;
  margin: 80px 80px 0 80px;
}

.leftColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-end;
}

.leftColumn .logo {
  font-size: 36px;
}

.socialIcons {
  display: flex;
  gap: 10px;
  margin-top: 44px;
}

.iconContainer {
  width: 45.333px;
  height: 45.333px;
  flex-shrink: 0;
  border-radius: 80px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconS {
  /* width: 18.333px;
  height: 18.667px; */
  flex-shrink: 0;
}

.header {
  color: #101010;
  font-family: Montserrat;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.btnDiv {
  display: flex;
  justify-content: end;
}
.connectButton {
  margin-top: 48px;
  display: inline-flex;
  padding: 11.026px;
  justify-content: center;
  align-items: center;
  gap: 11.026px;
  border-radius: 8.27px;
  background: #2d2d2d;
  box-shadow: 18.607px 18.607px 7.58px 0px rgba(0, 0, 0, 0),
    12.06px 12.06px 6.891px 0px rgba(0, 0, 0, 0.03),
    6.891px 6.891px 5.858px 0px rgba(0, 0, 0, 0.1),
    3.101px 3.101px 4.135px 0px rgba(0, 0, 0, 0.17),
    0.689px 0.689px 2.412px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.btnLogo {
  display: flex;
  width: 26px;
  height: 26px;
  justify-content: center;
  align-items: center;
  border-radius: 3.805px;
  flex-shrink: 0;
  background: linear-gradient(15deg, #f6af21 10.51%, #fef417 94.72%), #fff;
}

.dottedLines {
  border-top: 1px dotted #2c2c2c;
  margin: 50px 80px 32px 80px;
  height: 1.333px;
}

.bottomContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1920px;
  margin: 0 80px 33px 80px;
  flex-direction: row-reverse;
}

.copyright {
  color: #2c2c2c;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 1.52px;
  text-transform: uppercase;
}

.policies a {
  text-decoration: none;
  color: #2c2c2c;
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
}

.policies a:hover {
  text-decoration: underline;
}

.policies {
  display: flex;
  flex-direction: row;
  gap: 60px;
}

@media (max-width: 768px) {
  .footer {
    margin-top: 24px;
    padding-top: 12px;
    padding: 0 0px;
  }

  .heroSection {
    margin: 24px 20px;
    padding: 30px 20px;
    flex-direction: row;
    align-items: center;
    text-align: center;
    height: auto;

    /* display: inline-flex;
    padding: 4.63px 9.261px;
    justify-content: center;
    align-items: center;
    gap: 12.155px; */

    border-radius: 20.258px;
    /* background: #fff; */
  }

  .heroText {
    color: #fff;
    font-family: "Plus Jakarta Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 20.8px */
    letter-spacing: -0.48px;
  }

  .heroButton {
    padding: 10px 20px;
    font-size: 14px;
    gap: 20px;

    display: inline-flex;
    padding: 4.63px 9.261px;
    justify-content: center;
    align-items: center;
    gap: 12.155px;

    color: #010205;
    font-family: "Plus Jakarta Sans";
    font-size: 8px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 11.2px */
    letter-spacing: -0.16px;
    width: 61px;
    height: 30px;
  }

  .mainContent {
    flex-direction: column;
    align-items: center;
    margin: 0px 20px 0 0px;
  }

  .leftColumn {
    align-items: center;
    text-align: center;
    margin-bottom: 31px;
    gap: 111px;
    display: flex;
    flex-direction: row;
    /* align-items: flex-start; */
    align-self: flex-end;

    /* display: inline-flex;
    padding: 6.175px;
    justify-content: center;
    align-items: center;
    gap: 6.175px; */
  }
  .rightColumn {
    align-items: center;
    text-align: center;
    /* margin-bottom: 20px; */
  }

  .socialIcons {
    justify-content: center;
    margin-top: 0px;
    /* margin-right: 9px; */
  }

  .connectButton {
    margin-top: 12px;
    font-size: 12px;
    padding: 5px 10px;
    gap: 10px;

    display: inline-flex;
    padding: 6.175px;
    justify-content: center;
    align-items: center;
    gap: 6.175px;
    border-radius: 4.631px;
    background: #2d2d2d;
    box-shadow: 10.42px 10.42px 4.245px 0px rgba(0, 0, 0, 0),
      6.754px 6.754px 3.859px 0px rgba(0, 0, 0, 0.03),
      3.859px 3.859px 3.28px 0px rgba(0, 0, 0, 0.1),
      1.737px 1.737px 2.315px 0px rgba(0, 0, 0, 0.17),
      0.386px 0.386px 1.351px 0px rgba(0, 0, 0, 0.2);

    color: #fff;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Inter;
    font-size: 9.262px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }

  .dottedLines {
    margin: 24px 20px 16px 20px;
  }

  .bottomContent {
    flex-direction: row-reverse;
    align-items: center;
    margin: 0 20px 20px 20px;
  }

  .policies {
    /* margin-bottom: 20px; */
    gap: 8px;
    flex-direction: row;
    align-items: center;
  }

  .policies a {
    color: #2c2c2c;
    font-family: Poppins;
    font-size: 8px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 11.2px */
  }

  .iconContainer {
    width: 45.333px;
    height: 45.333px;
    flex-shrink: 0;
    border-radius: 80px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 22.667px;
    height: 22.667px;
    flex-shrink: 0;

    border-radius: 40px;
    background: #fff;
  }
  .logoImg {
    height: 1.2rem;
    width: 103px;
  }
  .iconS {
    /* font-size: 24px; */
    /* width: 9.333px;
    height: 18.667px; */
    flex-shrink: 0;

    /* width: 6px; */
    height: 9.333px;
    flex-shrink: 0;
  }
  .header {
    color: #101010;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .copyright {
    color: #2c2c2c;
    font-family: Montserrat;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 8px */
    letter-spacing: 0.76px;
    text-transform: uppercase;
  }
}
