/* index.css */
html {
  font-size: 16px; /* This should be consistent */
}

body {
  font-size: 1rem; /* 16px */
  font-family: "Poppins", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
