* {
  box-sizing: border-box;
}

.workContainer {
  text-align: center;
  margin-top: 80px;
  background: #f6f9ff;
}

.workDiv {
  padding: 100px 0px;
  margin: 0 auto;
}

.badge {
  display: inline-flex;
  padding: 12px 23px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  border: 1px solid #a1a1a1;
  color: #000;
  font-family: Poppins;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 28.8px */
}

.header {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0px;
}

.row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.imageContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.image {
  width: 100%;
  max-width: 486px;
  height: auto;
  transition: opacity 0.5s ease-in-out;
  margin-top: 48px;
}

.infoContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.infoRow {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.icon {
  font-size: 2rem;
  margin-right: 19px;
}

.sideHead {
  color: #1c1f35;
  font-family: Rubik;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: start;
}

.sideDesc {
  width: 468px;
  color: #666c89;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 151.523%; /* 24.244px */
  text-align: justify;
}

/* Responsive Styles */
@media (min-width: 768px) {
  .row {
    flex-direction: row;
  }

  .imageContainer {
    margin: 0;
  }
  .infoContainer {
    margin-top: 48px;
  }
}

@media (max-width: 768px) {
  .workContainer {
    text-align: center;
    margin-top: 40px;
    background: #f6f9ff;
  }

  .workDiv {
    padding: 18px 0px;
    margin: 0 auto;
  }

  .badge {
    display: inline-flex;
    padding: 5px 9px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 43.306px;
    border: 0.433px solid #a1a1a1;
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 9.6px */
  }

  .header {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 134%; /* 26.8px */
  }

  .imageContainer {
    margin: 0 24px;
  }

  .row {
    margin-top: 20px;
  }

  .infoRow {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .icon {
    margin: 0 0 10px 0;
  }

  .sideHead {
    text-align: center;
    width: 100%;
    color: #1c1f35;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .sideDesc {
    text-align: center;
    width: 100%;
    color: #666c89;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 151.523%; /* 18.183px */
  }

  .sideDesc {
    margin: 14px 35px 0px 35px;
    width: auto;
  }
  .infoContainer {
    margin-top: 3rem;
    gap: 24px;
  }
}
