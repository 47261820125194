.macroService {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 48px;
  margin: 0 5rem;
  margin-bottom: 80px;
}

.macroServiceHeader {
  display: flex;
  margin: 0 5rem;
  flex-direction: column;
}

.leftCol {
  display: flex;
  flex-direction: column;
}

.badge {
  display: ruby;
  width: fit-content;
  padding: 12px 23px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  border: 1px solid #0a47c9;
  color: #0a47c9;
  font-family: Poppins;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-bottom: 20px;
}

.description {
  width: 46vw;
  color: #000;
  font-family: Poppins;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 154%;
  margin-bottom: 32px;
}

.imagesContainer {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  width: 51vw;
  height: 21rem;
  margin-bottom: 32px;
}

.imageWrapper {
  position: relative;
  flex: 1;
  width: 183px;
  height: 276.226px;
  flex-shrink: 0;
}

.image {
  width: 100%;
  height: auto;
  display: block;
}

.imageText {
  position: absolute;
  bottom: -58px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 12px 7px;
  border-radius: 5px;
}

.imageText h3 {
  margin: 0;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}

.imageText p {
  margin: 5px 0 0 0;
  font-size: 10px;
}

.rightCol {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: start;
}

.rightDescription {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
}

.rightColImages {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  width: 34vw;
  height: 21rem;
  /* height: 16rem; */
}

.rightColImages + .rightColImages {
  margin-top: 15px;
}

.viewMoreBadge {
  display: inline-flex;
  width: fit-content;
  padding: 14px 24px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 70px;
  border: 1px solid #0a47c9;
  color: #0a47c9;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.32px;
  cursor: pointer;
}

/* Responsive design */
@media (max-width: 1024px) {
  .macroService {
    display: block;
    margin: 0 2rem;
  }
  .description {
    width: 100%;
  }
  .imagesContainer {
    width: 100%;
    height: 12rem;
  }
}

@media (max-width: 768px) {
  .macroServiceHeader {
    display: flex;
    margin: 40px 24px;
    flex-direction: column;
    align-items: center;
  }
  .leftCol {
    align-items: center;
    margin-top: 16px;
  }
  .rightCol {
    flex-direction: column;
    /* align-items: center; */
    justify-content: start;
    gap: 0rem;
  }
  .imagesContainer {
    justify-content: center;
    gap: 10px;
    width: 100%;
    margin-bottom: 0;
    height: 12rem;
  }
  .rightColImages {
    width: auto;
    height: 16rem;
  }
  .imageWrapper {
    width: 100%;
    height: auto;
  }
  .rightDescription {
    margin-bottom: 12px;
    display: none;
  }
  .viewMoreBadge {
    padding: 8px 16px;
    font-size: 10px;
    border-radius: 24px;
    border: 0.48px solid #0a47c9;
  }
  .badge {
    display: flex;
    justify-content: center;
    padding: 5px 9px;
    font-size: 10px;
    border-radius: 60px;
  }

  .imageText h3 {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 12px */
  }
  .imageText p {
    color: #fff;
    font-family: Poppins;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 154%; /* 9.24px */
    display: none;
  }
  .rightColImages + .rightColImages {
    margin-top: 35px;
  }
}

@media (max-width: 480px) {
  .macroService {
    margin: 0 1rem;
  }
  .description {
    font-size: 1.2rem;
    margin-bottom: 0;
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 8px;
    font-weight: 400;
    line-height: 134%;
  }
  .imagesContainer {
    gap: 8px;
    height: 12rem;
    margin-bottom: 6px;
  }
  .imageWrapper {
    width: 100%;
  }
  .imageText {
    position: absolute;
    font-size: 12px;
    padding: 8px 5px;
    /* top: 0; */
    /* bottom: 171px; */
    bottom: 8px;
    /* display: none; */
  }
  .viewMoreBadge {
    padding: 6px 12px;
    font-size: 8px;
    border-radius: 20px;
    margin-top: 48px;
  }
  .badge {
    display: flex;
    justify-content: center;
    padding: 5px 9px;
    font-size: 10px;
    border-radius: 60px;
  }
}
