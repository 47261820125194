* {
  box-sizing: border-box;
}

.blogContainer {
  padding-bottom: 80px;
  background: #0a47c9;
  margin-top: 66px;
  display: flex;
  justify-content: center;
}

.blogWrapper {
  background: #0a47c9;
  margin: 0px 80px 0px 80px;

  background: #0a47c9;
  margin: 0 40px; /* Adjusted to prevent overflow */
  max-width: 1200px; /* Added max-width to control overflow */

  box-sizing: border-box; /* Ensure padding does not affect width */
}

.headerRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  gap: 12px;
}

.headerCol {
  margin-top: 106px;
  /* width: 948px; */
  width: 60%;
}

.headerText {
  margin: 0px;
  color: #fff;
  font-family: "Plus Jakarta Sans";
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 62.4px */
  letter-spacing: -1.44px;
}

.descCol {
  width: 35%; /* Adjusted to ensure it fits within the blogWrapper */
  text-align: right;
  margin-top: 80px;
}

.paraText {
  color: #fff;
  font-family: "Plus Jakarta Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%; /* 28.8px */
  text-align: right;
}

.seeMoreButton {
  margin-top: 24px;
  width: 150px;
  padding: 17px 16px;
  justify-content: center;
  cursor: pointer;
  border-radius: 50px;
  border: 1px solid #fff;
  background: #0a47c9;
  color: #fff;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.32px;
}

.cardsRow {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.card {
  flex: 1;
  min-width: 300px;
  max-width: calc(33.333% - 1rem); /* Ensure cards fit within the row */

  border: 1px solid #ddd;

  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: flex;
  width: 525.185px;
  padding: 30px 30px 20px 30px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 19.028px;
  background: #fff;
}

.cardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;
}

.circleDot {
  width: 8px;
  height: 8px;
  background-color: #007bff;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.blogHeading {
  font-size: 1.25rem;
  /* margin-bottom: 0.5rem; */
}

.blogImage {
  width: 100%;
  height: auto;
  margin-top: 22px;
  margin-bottom: 22px;
  border-radius: 8px;
}

.shortText {
  width: 209.313px;
  height: 66px;
  flex-shrink: 0;
  overflow: hidden;
  color: #878c91;
  text-overflow: ellipsis;
  font-family: "Plus Jakarta Sans";
  font-size: 13.32px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 21.312px */
}

.cardFooter {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.readMore {
  flex: 1;
}

.time {
  overflow: hidden;
  color: #878c91;
  text-overflow: ellipsis;
  font-family: "Plus Jakarta Sans";
  font-size: 13.32px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 21.312px */
}

.readMoreButton {
  background-color: #fff;
  color: white;
  padding: 0.5rem;
  border-radius: 66.6px;
  border: 0.951px solid #010205;
  cursor: pointer;
  display: flex;
  width: 83px;
  align-items: center;
  gap: 171.256px;
  display: flex;
  padding: 15.223px 30.445px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.arrowImg {
  width: 22.834px;
  height: 22.834px;
}

/* Responsive Layout Adjustments */

@media (max-width: 1200px) {
  .blogWrapper {
    margin: 0 24px; /* Reduce margins on medium screens */
  }

  .headerCol {
    width: 100%; /* Full width on smaller screens */
    margin-top: 20px; /* Adjust margin for smaller screens */
  }

  .descCol {
    width: 100%; /* Full width on smaller screens */
    text-align: center; /* Center align text on smaller screens */
    margin-top: 20px; /* Adjust margin for smaller screens */
  }

  .cardsRow {
    gap: 1rem; /* Ensure cards have space on medium screens */
  }

  .card {
    width: 100%; /* Full width on smaller screens */
    padding: 1rem; /* Adjust padding for smaller screens */
  }
}

@media (max-width: 992px) {
  .headerText {
    font-size: 36px; /* Reduce font size for smaller screens */
  }

  .paraText {
    font-size: 14px; /* Reduce font size for smaller screens */
  }

  .seeMoreButton {
    width: 120px; /* Reduce button width for smaller screens */
    font-size: 14px; /* Reduce font size for button */
  }

  .card {
    width: calc(50% - 1rem); /* 2 cards per row */
  }
}

@media (max-width: 768px) {
  .blogWrapper {
    margin: 0 24px; /* Reduce margins on medium screens */
  }
  .blogContainer {
    padding-bottom: 16px;
    background: #0a47c9;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  .headerRow {
    flex-direction: column; /* Stack header content vertically */
    margin-top: 16px;
  }

  .headerCol,
  .descCol {
    width: 100%; /* Full width for both columns */
    margin-top: 0; /* Remove top margin */
    text-align: center; /* Center align text */
  }

  .cardsRow {
    flex-direction: column; /* Stack cards vertically */
    align-items: center;
  }

  .card {
    width: 100%; /* Full width for each card */
    padding: 1rem; /* Adjust padding for smaller screens */
    margin-bottom: 1rem; /* Add margin between cards */

    display: flex;
    width: 382.134px;
    padding: 22.153px;
    flex-direction: column;
    align-items: flex-start;
    /* gap: 23.284px; */
    flex-shrink: 0;

    border-radius: 13.845px;
    background: #fff;
  }
  .cardFooter {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
  }
  .blogHeading {
    font-size: 1rem; /* Adjust font size for headings */
    margin-bottom: 23px;
  }
  .headerText {
    color: #fff;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: "Plus Jakarta Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 20.8px */
    letter-spacing: -0.48px;
  }
  .paraText {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 134%; /* 10.72px */
  }
  .shortText {
    width: 152px;
    font-size: 12px; /* Adjust font size for short text */
  }

  .readMoreButton {
    width: 70px; /* Adjust button width */
    padding: 10px; /* Adjust padding */
  }

  .arrowImg {
    width: 16px; /* Adjust arrow icon size */
    height: 16px; /* Adjust arrow icon size */
  }
  .blogImage {
    width: 100%;
    height: auto;
    margin: 0;
    /* display: flex; */
    /* width: 382.134px; */
    /* padding: 22.153px; */
    flex-direction: column;
    align-items: flex-start;
    gap: 23.284px;
    flex-shrink: 0;
    border-radius: 13.845px;
    background: #fff;
    /* margin-top: 32px; */
    /* margin-bottom: 32px; */
  }
}

@media (max-width: 576px) {
  .blogWrapper {
    margin: 0 24px; /* Reduce margins on medium screens */
  }
  .headerText {
    color: #fff;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: "Plus Jakarta Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 20.8px */
    letter-spacing: -0.48px;
  }

  .paraText {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 134%; /* 10.72px */
  }

  .seeMoreButton {
    width: 100px; /* Further reduce button width */

    display: inline-flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 24px;
    border: 0.48px solid #fff;
    color: #fff;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 14px */
    letter-spacing: -0.2px;
  }

  .card {
    width: 100%; /* Full width for cards on very small screens */
    padding: 0.5rem; /* Reduce padding */
  }

  .blogHeading {
    font-size: 17px; /* Further reduce font size for headings */
  }

  .shortText {
    font-size: 10px; /* Further reduce font size for short text */
  }

  .readMoreButton {
    width: 60px; /* Further reduce button width */
    padding: 8px; /* Further reduce padding */
  }

  .arrowImg {
    width: 14px; /* Further reduce arrow icon size */
    height: 14px; /* Further reduce arrow icon size */
  }
}
