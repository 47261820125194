* {
  box-sizing: border-box;
}

.container {
  text-align: center;
  padding: 20px;
  background: #0940b5;
  margin-top: 5rem;
}

.badge {
  margin-top: 5rem;
  display: inline-flex;
  padding: 0.75rem 1.4375rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 6.25rem;
  border: 1px solid #fff;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 28.8px */
}

.header {
  margin: 2rem 0 3rem 0;
  color: #fff;
  font-family: Poppins;
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  line-height: 132%; /* 73.92px */
}

.tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 4.375rem;
  gap: 1.25rem;
}

.tabButton {
  display: flex;
  padding: 0.625rem 1rem;
  justify-content: center;
  align-items: flex-end;
  gap: 2rem;
  flex-shrink: 0;
  color: #fff;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.32px;
  cursor: pointer;
  border: none;
  background-color: #0940b5;
  color: #fff;
  border-radius: 50px;
  border: 1px solid #fff;
}

.tabButton.active {
  background: #fff;
  color: #0a47c9;
}

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.countries {
  flex: 1 1 30%;
  padding: 0.625rem;
}

.imageContainer {
  flex: 1 1 70%;
  padding: 0.625rem;
}

.countries ul {
  list-style-type: none;
  padding: 0;
}

.countries li {
  color: #fff;
  font-family: Poppins;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 49.143px */
  letter-spacing: -0.41px;
  margin-bottom: 32px;
}

.imageContainer img {
  width: 70%;
  flex-shrink: 0;
}

/* Responsive design */
@media (max-width: 768px) {
  .container {
    text-align: center;
    padding: 0px;
    background: #0940b5;
    margin-top: 24px;
  }

  .content {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: unset;
    flex-direction: column-reverse;
  }
  .countries {
    flex: 1 1 100%;
    padding: 0px 0px;
    margin-top: 16px;
  }
  .imageContainer {
    flex: 1 1 100%;
  }

  .imageContainer img {
    width: 100%;
    height: auto;
  }
  .badge {
    margin-top: 16px;
    display: inline-flex;
    padding: 5px 9px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 43.306px;
    border: 0.433px solid #fff;
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 9.6px */
  }

  .header {
    margin: 12px 0 0 0;
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 134%; /* 26.8px */
  }

  .tabButton {
    display: flex;
    width: 82.113px;
    align-items: center;
    gap: 26.631px;
    color: #fff;
    font-family: Poppins;
    font-size: 8.877px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 12.428px */
    letter-spacing: -0.178px;
  }

  .tabButton.active {
    display: flex;
    width: 82.113px;
    padding: 9.432px 8.877px;
    justify-content: center;
    align-items: flex-end;
    gap: 17.754px;
    flex-shrink: 0;
    color: #0a47c9;
    font-family: Poppins;
    font-size: 8.877px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 12.428px */
    letter-spacing: -0.178px;
  }

  .tabs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 16px 6px 0px 6px;
    gap: 11px;
  }

  .countries li {
    color: var(--Neutral-10, #fff);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 14.4px */
    letter-spacing: -0.12px;
    margin-bottom: 16px;
  }

  .countries ul {
    display: flex;
    justify-content: center;
    gap: 16px;
    flex-wrap: wrap;
    row-gap: 0px;
  }
}
