.servicesContainer {
  text-align: center;
  margin-top: 135px;
  background: #fcfcfc;
}

.badge {
  display: inline-flex;
  padding: 12px 23px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  border: 1px solid #a1a1a1;
  color: #000;
  font-family: Poppins;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 28.8px */
  margin-top: 75px;
}

.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 53px; /* Gap between rows */
  column-gap: 100px; /* Gap between columns */
  opacity: 1;
  transition: opacity 0.3s ease;
  max-width: 1200px;
  margin: 80px auto 0 auto;
  background: #fcfcfc;
}

.card {
  width: 270px;
  height: 185px;
  text-align: left;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.icon {
  height: 48px;
  width: 48px;
  margin-bottom: 22px;
}

.cardName {
  color: #1c1f35;
  font-family: Rubik, sans-serif;
  font-size: 25.01px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.cardDescription {
  color: #808080;
  font-family: Poppins, sans-serif;
  font-size: 16.006px;
  font-style: normal;
  font-weight: 500;
  line-height: 151.523%; /* 24.253px */
  margin-top: 13px;
}

.viewMore {
  margin-top: 88px;
  width: 176px;
  padding: 16px;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  border-radius: 50px;
  border: 1px solid #0a47c9;
  color: #0a47c9;
  font-family: Poppins;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.32px;
  background-color: #fff;
  transition: background-color 0.3s;
  margin-bottom: 80px;
}

.showMore {
  opacity: 0;
  animation: fadeIn 0.3s forwards;
}

.hide {
  opacity: 0;
  animation: fadeOut 0.3s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@media (max-width: 1024px) {
  .cardsContainer {
    column-gap: 50px; /* Adjust gap for smaller screens */
    row-gap: 53px; /* Maintain row gap */
  }
  .card {
    width: 270px; /* Maintain card width */
  }
}

@media (max-width: 767px) {
  .servicesContainer {
    text-align: center;
    margin-top: 56px;
    background: #fcfcfc;
  }
  .cardsContainer {
    flex-direction: column;
    align-items: center;
    column-gap: 20px; /* Adjust gap for smaller screens */
    row-gap: 53px; /* Maintain row gap */
  }

  .badge {
    display: inline-flex;
    padding: 5px 9px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 43.306px;
    border: 0.433px solid #a1a1a1;

    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 9.6px */
  }
  .card {
    width: 100%; /* Full width on small screens */
    max-width: 270px; /* Max width to maintain card size */
    text-align: center;
  }

  .cardsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 0px; /* Gap between rows */
    column-gap: 100px; /* Gap between columns */
    opacity: 1;
    transition: opacity 0.3s ease;
    max-width: 1200px;
    margin: 50px auto 0 auto;
  }

  .cardName {
    color: #1c1f35;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .cardDescription {
    text-align: center;
    font-size: 12px;
  }

  .viewMore {
    /* display: flex; */
    width: 84.48px;
    margin-top: 0px;
    padding: 8.16px 7.68px;
    justify-content: center;
    align-items: flex-end;
    gap: 15.36px;
    flex-shrink: 0;

    color: #0a47c9;
    font-family: Poppins;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 14px */
    letter-spacing: -0.2px;
    margin-bottom: 15px;
  }
}
