.heroSection {
  position: relative;
  width: calc(100% - 160px); /* Subtracting the left and right margins */
  /* height: 392px; */
  height: 512px;
  margin: 0px 80px;
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  justify-content: start;
  align-items: center;
  overflow: hidden;
}

.heroImage {
  width: 100%;
  height: auto;
  max-width: calc(
    100% - 48px
  ); /* Ensure it does not exceed its intended maximum width */
  object-fit: cover; /* Cover the area while maintaining aspect ratio */
  margin: 0 24px; /* Left and right margin */
}

.scrollContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px; /* Space between image and scroll container */
}

.dots {
  display: flex;
  flex-direction: column;
  gap: 7px; /* Adjust gap between dots if necessary */
  margin-top: 10px; /* Space between text and dots */
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.dot1 {
  width: 17px;
  height: 17px;
  flex-shrink: 0;
  border-radius: 17px;
  background: #0a47c9;
}

.dot2 {
  width: 17px;
  height: 17px;
  flex-shrink: 0;
  border-radius: 17px;
  background: #e1e9f8;
}

.dot3 {
  width: 17px;
  height: 17px;
  flex-shrink: 0;
  border-radius: 17px;
  background: #e1e9f8;
}

.scrollText {
  color: #0a47c9;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2; /* 120% of font size */
}

@media (max-width: 1760px) {
  .heroImage {
    max-width: 100%;
  }
}

@media (max-width: 1024px) {
  .heroSection {
    width: calc(100% - 120px); /* Adjusting margins for medium devices */
    margin: 17px 60px;
  }
  .dot {
    width: 10px;
    height: 10px;
  }
}

@media (max-width: 768px) {
  .heroSection {
    width: calc(100% - 48px); /* Adjusting margins for small devices */
    margin: 10px 24px;

    position: relative;
    width: calc(100% - 160px); /* Subtracting the left and right margins */
    height: 234px;
    margin: 17px 80px;
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
  }
  .scrollContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px; /* Space between image and scroll container */
  }
  .scrollText {
    font-size: 0.875rem; /* 14px */
  }
  .dot {
    width: 8px;
    height: 8px;
    flex-shrink: 0;
    border-radius: 8px;
  }

  .dot1 {
    background: #0a47c9;
  }

  .dot2,
  .dot3 {
    background: #e1e9f8;
  }
}

@media (max-width: 480px) {
  .heroSection {
    width: 100%;
    margin: 10px 0px;
  }
  .heroImage {
    width: 100%;
    height: auto;
    max-width: 381.783px; /* Keeping specified dimensions */
    height: 134.568px; /* Keeping specified dimensions */
    flex-shrink: 0;
  }
  .scrollText {
    margin-top: 12px;
    font-size: 10px;
  }

  .dot {
    width: 8px;
    height: 8px;
    flex-shrink: 0;
    border-radius: 8px;
  }

  .dot1 {
    background: #0a47c9;
  }

  .dot2,
  .dot3 {
    background: #e1e9f8;
  }
}
