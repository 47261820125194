* {
  box-sizing: border-box;
}
.mainBlog {
  padding: 20px;
  /* margin: 0 80px; */
}

.blogRow {
  display: flex;
  flex-direction: row;
  gap: 5vw; /* Relative gap for responsiveness */
  flex-wrap: wrap;
}

.blogImage img {
  width: 42.19vw; /* Approx 810px based on a 1920px wide viewport */
  height: 25.4vw; /* Approx 485px based on a 1920px wide viewport */
  border-radius: 8px;
  flex-shrink: 0;
}

.blogDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 50%;
}

.author {
  color: #6d7a65;
  font-family: Poppins, sans-serif;
  font-size: 1rem; /* 16px */
  font-weight: 600;
  letter-spacing: 0.09375rem; /* 1.5px */
  text-transform: uppercase;
}

.blogHeader {
  margin: 10px 0;
  font-size: 1.8rem; /* 28.8px */
  color: #2b3029;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}

.blogMeta {
  display: flex;
  gap: 0.3125rem; /* 5px */
  color: #707070;
  font-family: Poppins, sans-serif;
  font-size: 1rem; /* 16px */
  font-weight: 400;
  letter-spacing: 0.09375rem; /* 1.5px */
  text-transform: uppercase;
}

@media (max-width: 1024px) {
  .blogRow {
    flex-direction: column;
    gap: 4vw;
  }

  .blogImage img {
    width: 100%; /* Make the image full width in smaller screens */
    height: auto;
  }

  .blogHeader {
    font-size: 1.5rem; /* 24px */
  }

  .author,
  .blogMeta {
    font-size: 0.875rem; /* 14px */
  }
}

@media (max-width: 768px) {
  .blogRow {
    flex-direction: column;
    gap: 3vw;
  }

  .blogImage img {
    width: 100%; /* Make the image full width in smaller screens */
    height: auto;
  }

  .blogHeader {
    font-size: 1.25rem; /* 20px */
  }

  .author,
  .blogMeta {
    font-size: 0.8125rem; /* 13px */
  }
}

@media (max-width: 480px) {
  .blogRow {
    gap: 2vw;
  }

  .blogHeader {
    font-size: 1rem; /* 16px */
  }

  .author,
  .blogMeta {
    font-size: 0.75rem; /* 12px */
  }
}
