.container {
  width: 100%;
  background: #edf8ff;
}

.wrapper {
  margin: 80px 160px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header h1 {
  color: #0a47c9;
  font-family: Poppins;
  font-size: 4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  text-transform: capitalize;
}

.tabs {
  display: flex;
  gap: 17px;
}

.tab {
  width: max-content;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 1px solid #0a47c9;
  color: #0a47c9;
  font-family: Poppins;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  text-transform: capitalize;
  background-color: #fff;
  padding: 8px 16px;
}

.tab.active {
  color: white;
  border: 1px solid #f6f7f6;
  background: #0a47c9;
  color: #fff;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-transform: capitalize;
  padding: 8px 16px;
}

.blogs {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.blogCard {
  width: calc(33.333% - 20px);
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.blogImage {
  width: 100%;
  height: auto;
}

.blogDetails {
  padding: 10px;
}

.blogAuthor {
  font-size: 0.875rem;
  color: #555;
}

.blogHeader {
  font-size: 1rem;
  margin: 10px 0;
}

.blogDate {
  font-size: 0.875rem;
  color: #777;
}

.buttons {
  text-align: center;
}

.viewMore,
.viewLess {
  width: 176px;
  padding: 17px 16px;
  justify-content: center;
  align-items: flex-end;
  border-radius: 50px;
  border: 1px solid #0a47c9;
  color: #0a47c9;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.32px;
  background-color: #fff;
  margin-top: 100px;
  margin-bottom: 100px;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .wrapper {
    margin: 60px 120px;
  }

  .header h1 {
    font-size: 3rem;
  }

  .tabs {
    gap: 15px;
  }

  .tab {
    padding: 14px 6px;
    font-size: 1rem;
  }

  .tab.active {
    font-size: 20px;
  }

  .blogs {
    gap: 15px;
  }

  .blogCard {
    width: calc(50% - 15px);
  }
}

@media (max-width: 768px) {
  .wrapper {
    margin: 40px 20px;
  }

  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .header h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }

  .tabs {
    width: 100%;
    flex-direction: column;
    gap: 10px;
  }

  .tab {
    width: 100%;
    padding: 12px;
    font-size: 0.9rem;
  }

  .tab.active {
    font-size: 18px;
  }

  .blogs {
    gap: 10px;
  }

  .blogCard {
    width: 100%;
  }

  .viewMore,
  .viewLess {
    width: 150px;
    padding: 12px;
    font-size: 14px;
  }
}
