/* General styles */
.formContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 80px;
  padding-top: 143px;
  background: #000a20;
  justify-content: center;
  column-gap: 70px;
  row-gap: 70px;
  overflow: hidden; /* Prevent overflow */
}

.leftColumn,
.rightColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #fff;
}

.leftColumn {
  width: 350px;
  gap: 5px;
}

.rightColumn {
  margin-top: 42px;
}

.leftColumn h2,
.rightColumn h2 {
  color: #fff;
  font-family: Rubik;
  font-size: 35px;
  font-weight: 600;
  line-height: normal;
}

.leftColumn p,
.rightColumn p {
  color: #fff;
  font-family: Krub;
  font-size: 16px;
  font-weight: 500;
  line-height: 151.523%;
}

.contactInfo {
  margin-top: 32px;
}

.contactRow {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.icon {
  margin-right: 10px;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 31px;
  gap: 20px;
}

.inputField,
.textArea {
  padding: 10px;
  border: 1px solid #4e5683;
  border-radius: 4px;
  background: #000a20;
  color: #fff;
  font-family: "League Spartan";
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
}

.inputField {
  width: 260px;
}

.inputField::placeholder,
.textArea::placeholder {
  color: #fff;
}

.textArea {
  width: 40vw;
  margin-bottom: 20px;
  height: 100px;
}

.sendButton {
  color: #fff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  width: 176px;
  padding: 17px 16px;
  justify-content: center;
  align-items: center;
  background: #000a20;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.32px;
  border: 1px solid #fff;
}

/* Image slider styles */
.imageRow {
  display: flex;
  width: 200%; /* Adjust width to accommodate duplicated images */
  justify-content: flex-start;
  margin-bottom: 143px;
  overflow: hidden; /* Ensure images are clipped to container */
  position: relative;
}

.image {
  width: 243px;
  height: 234px;
  flex-shrink: 0;
  animation: slide 5s linear infinite; /* Adjust duration for desired speed */
}

/* Keyframes for sliding effect */
@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .formContainer {
    padding-top: 18px;
    flex-direction: column;
    /* align-items: center; */
    row-gap: 0px;
    margin-top: 24px;
    overflow: hidden; /* Prevent overflow */
  }

  .leftColumn,
  .rightColumn {
    width: 100%;
    align-items: center;
  }

  .leftColumn {
    margin-top: 0;
  }

  .leftColumn h2,
  .rightColumn h2 {
    font-family: Poppins;
    font-size: 20px;
  }

  .leftColumn p,
  .rightColumn p {
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    line-height: 151.523%;
    margin: 0 2px;
  }

  .contactInfo {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    gap: 7px;
  }

  .contactRow {
    flex-direction: column;
    width: 117px;
    gap: 8px;
    margin-bottom: 30px;
  }

  .contactText {
    text-align: center;
    font-family: Poppins;
    font-size: 10px;
    line-height: 135.023%;
  }

  .row {
    flex-direction: column;
    width: 100%;
    gap: 10px;
  }

  .inputField {
    width: 300px;
  }

  .textArea {
    width: 100%;
    margin: 0;
  }

  .sendButton {
    width: 50%;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    border: 0.48px solid #fff;
    font-size: 10px;
    margin-top: 12px;
  }

  .imageRow {
    flex-direction: row;
    margin-top: 24px;
    margin-bottom: 0;
  }

  .image {
    width: 95.818px;
    height: 74.49px;
    margin-bottom: 20px;
  }
}
