.container {
  margin-top: 7.5rem;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 80px;
}

.blankCol {
  width: 33%;
}

.contentCol {
  width: 67%; /* Keep this at 50% to align with the blank column */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.badge {
  margin-top: 5rem;
  display: inline-flex;
  padding: 0.75rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  border-radius: 2rem;
  border: 1px solid #0a47c9;
  color: #0a47c9;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.2;
}

.headerDiv {
  display: flex;
  align-items: center; /* Center align items in the header */
  margin-top: 3rem;
  width: 100%;
}

.header {
  margin: 0;
  color: #000;
  font-family: Poppins, sans-serif;
  font-size: 48px; /* Font size */
  font-weight: 600;
  line-height: 1.34;
  text-align: left;
  width: 635px; /* Set fixed width */
}

.description {
  font-size: 12px; /* Font size */
  /* margin-left: 60px; */
  width: 317px; /* Set fixed width */
}

.imageContainer {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: 3.5rem;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  width: 100%;
}

.imageWrapper {
  position: relative;
  display: flex;
  width: 20vw;
  max-width: 318px;
  height: 442px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  border-radius: 2rem;
  background: #2d2d2d;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  opacity: 0.7;
  transition: opacity 0.3s;
}

.imageWrapper:hover .image {
  opacity: 0.5;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem; /* Optional: Add padding for spacing */
  background: rgba(
    0,
    0,
    0,
    0.5
  ); /* Optional: Add background for better visibility */
  opacity: 0; /* Hidden by default */

  transition: opacity 0.3s;
}

.imageWrapper:hover .overlay {
  opacity: 1; /* Show on hover */
}

.hoverHead {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 134%; /* 42.88px */
}

.hoverText {
  display: flex;
  width: 277px;
  height: 236px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: white;
  margin-top: 32px; /* Space between header and text */
  padding: 0 21px;
}

.header {
  font-size: 3vw; /* Keep the updated size */
  margin: 0;
}
.description {
  font-size: 0.83vw; /* Keep the updated size */
}
/* Responsive styles */
@media (min-width: 768px) {
  .headerDiv {
    flex-direction: row; /* Ensure header and description are in a row */
    justify-content: flex-start;
    width: 100%;
    /* width: 382px; */
  }

  .header {
    font-size: 3vw; /* Keep the updated size */
    margin: 0;
  }
}

@media (max-width: 768px) {
  .container {
    flex-direction: column; /* Stack columns on small screens */
    align-items: flex-start; /* Align items to the start */

    margin-top: 56px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 0px;
  }

  .blankCol {
    /* width: 100%; 
    height: 2rem;  */
    display: none;
  }

  .contentCol {
    width: 100%; /* Full width for content column */
    align-items: center; /* Align items to the start */
  }

  .badge {
    margin-top: 40px; /* Adjust margin for mobile */
    font-size: 1.2rem; /* Reduce font size for mobile */

    display: inline-flex;
    padding: 5px 9px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 43.306px;
    border: 0.433px solid #0a47c9;

    color: #0a47c9;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 9.6px */
  }
  .headerDiv {
    display: flex;
    align-items: center;
    margin-top: 13px;
    width: 100%;
    flex-direction: column;
    text-align: center;
    width: 382px;
  }
  .header {
    width: 100%; /* Full width */
    margin: 0 24px; /* Remove default margin */
    /* width: 382px; */
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 134%; /* 26.8px */
  }

  .description {
    width: 100%; /* Full width */
    margin-top: 12px; /* Add some space above */

    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 134%; /* 10.72px */
  }

  .imageContainer {
    flex-direction: column; /* Stack images on smaller devices */
    align-items: center; /* Center align images */
    gap: 2rem; /* Adjust spacing between images */
    margin-top: 2rem; /* Adjust margin for better spacing */
  }

  .imageWrapper {
    /* width: 80vw; */
    /* max-width: 300px; */
    /* height: auto; */

    width: 404px;
    height: 311px;
  }
  .hoverHead {
    font-size: 22px;
  }
  .hoverText {
    font-style: 10px;
    padding: 0 0px;
    margin-top: 0px;
  }
}
